body {
    background-color: #3A3845;
    color:white;
  }
  .header {
    text-align: center;
    font-size: 3rem;
  }
  .portTitle  {
    height:10vh ;
    margin-bottom: 0 ;
    text-align: center ;
  }
  .subtitle {
    font-size: 2rem!important;
    text-align: center;
  }
  .removeDec {
    text-decoration: none;
    color: #3A3845;
    filter: drop-shadow(16px 16px 10px black);
  }
  .removeDec:hover{
    color:white
  }
  .imageHover:hover {
    opacity: 50%;
  }
  
  .left {
    text-align:right;
  }
  .homeContent {
    align-items:center !important;
    padding-bottom: 200px;
    font-size: 1rem;
    height: 92.5vh;
    margin:0;
  }
  .image {
    max-width: 350px;
    width:100%;
  }
  .size {
    height: 10px;
  }
  
  #projects {
    background-color: #F7CCAC;
    color: #3A3845;
  }
  .imgRow {
    --bs-gutter-x:"0"
  }