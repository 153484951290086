.content {
    /* border-style: solid; */
    /* border-width: 0 .5px; */
    /* border-color: black; */
}

.listaBody {
    background-image: url("https://www.transparenttextures.com/patterns/argyle.png");
    background-color: #292929;
    min-height: 100vh;
    color:"black"
}

.listaCol {
    padding:0;
    /* border-style: solid; */
    /* border-bottom-width: 1px; */
    /* border-width: 0 .5px 1px .5px; */
    /* border-width: 1px; */
    /* border-color: black; */
}
.listaContainer {
    /* border-radius: 5%; */
}
h1 {
    color:black;
    /* padding:0px !important; */
}

.listaButtons {
    width:100%;
    font-size: large;
    background-color: inherit;
    color: black;
    border-radius: 0%;
    border-width: 0;
    
}
.listaButtons:focus {
    outline: none;
    box-shadow: none;
  }
.listaButtons:hover {
    background-color: grey;
}
.listaTitle {
    text-align: center;
}

.copyButton {
    background-color: #D2DAFF;
    border-color: #292929;
    box-shadow: 4px 5px 5px black;
    text-decoration: none !important;
    outline: none !important;
    outline-offset: none !important;
    color:black;
}
.copyButton:focus {
    outline: none !important;
    outline-offset: none !important;
    box-shadow: 4px 5px 5px black;
    background-color: #B1B2FF;
    border-color: #292929;
}
.copyButton:active {
    background-color: #B1B2FF !important;
    box-shadow: none !important;
}
.copyButton:hover{
    background-color: #B1B2FF;
    text-decoration: none !important;
    border-color: #292929;
}

.productInput {
    font-size: 1.2rem;
}
.plus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center; 
  }

  .grid {
    background-color: #FFCEFE;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom:0;
  }
  .stores {
      background-color: #7286D3;
      position: fixed;
      padding: 7px 0 7px 0;
      bottom:0
  }
  #lista {
    background: #8EA7E9 !important;
    min-height: 100vh;
    height:100%;
    padding: 30px 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }