.button1 {
  height: 100%;
  width: 100%;
  color: #eeeeee;
  background-color: #393e46;
  border-width: 0.01;
  border-color: #222831;
  font-size: 2rem;
}
.button1:hover {
  background-color: #585e68f6;
}
.homeTitle {
  background-color: #3A3845;
  text-align: center;
  justify-content: center;
  margin: auto;
  font-size: 2.5rem;
  line-height: 10vh;
  height: 10vh;
}
.homeRow {
  height: 45vh;
  width: 100%;
  padding: 0;
  margin: 0;
}
.homeCol {
  text-align: center;
  padding: 0;
}
.container {
  margin: 0;
  max-width: 100%;
  padding: 0;
}

.small {
    font-size: 1rem;
}