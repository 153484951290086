.button-45 {
  align-items: center;
  background-color: #ffe7e7;
  background-position: 0 0;
  border: 1px solid #fee0e0;
  border-radius: 11px;
  box-sizing: border-box;
  color: #d33a2c;
  cursor: pointer;
  display: flex;
  font-size: 2rem;
  font-weight: 700;
  line-height: 33.4929px;
  list-style: outside url(https://www.smashingmagazine.com/images/bullet.svg)
    none;
  padding: 8px 24px;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-underline-offset: 1px;
  transition: border 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  word-break: break-word;
}

/* .button-45:active,
.button-45:hover,
.button-45:focus {
  outline: 0;
} */

/* .button-45:active {
  background-color: #D33A2C;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px 0 inset;
  color: #FFFFFF;
} */
.contain {
  /* width: 300px; */
  height: 150px;
  margin: 0 auto;
  /* background-color: yellow; */
  /* important part */
  display: grid;
  place-items: center;
  grid-template-areas:
                  "inner-div";
}

.inner {
  height: 100px;
  width: 80%;
  /* important part */
  grid-area: inner-div;
}

.button-45:hover {
  background-color: #ffe3e3;
  border-color: #faa4a4db;
}

/* .button-45:active:hover,
.button-45:focus:hover,
.button-45:focus {
  background-color: #D33A2C;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 3px 0 inset;
  color: #FFFFFF;
} */

.back:hover {
  color: rgb(199, 199, 199) !important;
}
.arrowButton:active {
  color: lightblue;
}

.button-33 {
  background-color: #fe3838;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, 0.2) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.15) 0 1px 2px, rgba(44, 187, 99, 0.15) 0 2px 4px,
    rgba(44, 187, 99, 0.15) 0 4px 8px, rgba(44, 187, 99, 0.15) 0 8px 16px,
    rgba(44, 187, 99, 0.15) 0 16px 32px;
  color: rgb(217, 217, 217);
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-33:hover {
  box-shadow: rgba(44, 187, 99, 0.35) 0 -25px 18px -14px inset,
    rgba(44, 187, 99, 0.25) 0 1px 2px, rgba(44, 187, 99, 0.25) 0 2px 4px,
    rgba(44, 187, 99, 0.25) 0 4px 8px, rgba(44, 187, 99, 0.25) 0 8px 16px,
    rgba(44, 187, 99, 0.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}

.button-82-pushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-82-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.button-82-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    hsl(340deg 100% 16%) 0%,
    hsl(340deg 100% 32%) 8%,
    hsl(340deg 100% 32%) 92%,
    hsl(340deg 100% 16%) 100%
  );
}

.button-82-front {
  display: block;
  position: relative;
  padding: 12px 27px;
  border-radius: 12px;
  font-size: 1.1rem;
  color: white;
  background: hsl(345deg 100% 47%);
  will-change: transform;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

@media (min-width: 768px) {
  .button-82-front {
    font-size: 1.25rem;
    padding: 12px 42px;
  }
}

.button-82-pushable:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

.button-82-pushable:hover .button-82-front {
  transform: translateY(-6px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.button-82-pushable:active .button-82-front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.button-82-pushable:hover .button-82-shadow {
  transform: translateY(4px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.button-82-pushable:active .button-82-shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.button-82-pushable:focus:not(:focus-visible) {
  outline: none;
}

.clipboardButton:active {
  background-color: white !important;
}

.disabledText {
  background: rgba(211, 211, 211, 0.876) !important;
}

.textField {
  background: rgba(255, 255, 255, 0.873);
  border: 1px !important ;
  border-style: solid !important;
  border-color: black !important;
}

.timer {
  max-height: 200px;
  max-width: 50%;
  aspect-ratio: 1;
  margin: auto;
  background: #332ebdf0;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px rgb(181, 1, 1);
  border-style: solid;
}
.graphBackground {
  background: white;
}

.increments {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  height: 30px;
  aspect-ratio: 1/1;
  background-color: transparent;
  border-width: 1.2px;
  border-style: solid;
  border-radius: 20%;
  box-shadow: 3px 2px 5px black;
  cursor:pointer;
  text-align: center;
  margin:auto;
}

.increments:active {
  background-color:white;
  box-shadow: none;
}
